.component-root {
  padding: 50px;
  h1 {
    margin-bottom: 50px;
  }
  .menu-panel {
    margin-bottom: 10px;
  }
  .input-group {
    margin: 0.5em 0;
    .error {
      border: 1px solid #f44336;
    }
  }
  .additionsChecker {
    .rs-checkbox-inline {
      margin-left: 5px;
      .rs-checkbox-checker {
        padding: 10px 0 5px 30px;
      }
    }
  }
}
.rs-loader-wrapper {
  height: 100vh;
  width: 100%;
  position: relative;
}
