@media print {
  .printModal {
    background-color: white;
    height: auto;
    width: 120%;
    position: absolute;
    top: 0;
    left: 0;
    margin: 0 -50px;
    padding: 0;
    font-size: 14px;
    line-height: 18px;
  }
  .printContainer {
    background-color: #FFF;
    color: #000;
    width: 100%;
    margin-left: -20px;
    margin-right: -100px;
  }
  .noPrint {
    display: none;
  }
}
.printContainer {
  .cwContainer {
    width: 100%;
    border-bottom: 1px solid #c1c1c1;
    margin: 10px 0;

    .cwHeadline {
      font-size: 18px;
      font-weight: bold;
    }

    .weekday {
      padding: 10px;
      border: 1px solid #666;
      margin: 25px 5px;

      .weekdayHeadline {
        font-weight: bold;
      }
    }
  }
}

.printOnly {
  background-color: #FFF;
  color: #000;
  padding: 10px;
  height: 100vh;
  .rs-table, .rs-table-row, .rs-tabel-cell {
    background: #FFFFFF !important;
    color: #000000
  }
  div {
    background: #FFFFFF !important;
    color: #000000
  }
  .additionsContainer {
    font-size: 11px;
    color: #000;
  }
}

