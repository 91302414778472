@media print {
  .printModal {
    background-color: white;
    height: auto;
    width: 100%;
    position: absolute;
    top: 0;
    left: -100px;
    margin: 0;
    padding: 15px;
    font-size: 14px;
    line-height: 18px;
    .printOrderTable {
      width: 100%;
      color: black;
    }
  }
  .noPrint {
    display: none;
  }
}
