#login-container {
  .login-flex {
    width: 100vw;
    height: 100vh;
    .login-panel {
      padding: 20px;
      input {
        margin: 1em 0;
      }
    }
  }
}
